<template>
	<v-layout shrink align-center mx-2>
		<DocumentZoomButtons v-if="$vuetify.breakpoint.xlOnly || conditionalDisplay.isMobileApp" />
		<RotateDocumentButtons
			v-if="value && value.latestVersion && (version === null || version.id === value.latestVersion.id) && isEditable && !conditionalDisplay.isMobileApp"
			v-model="model"
			:flat="false"
			:list-tile-display="false"
			:simple-button-display="true"
		/>
		<PrintButton v-if="model && !conditionalDisplay.isMobileApp" v-model="model" simple-button-display :flat="false" :version="version" />
		<UniqueNumberButton v-if="model && !conditionalDisplay.isMobileApp" v-model="model" simple-button-display />
		<ValidationButtons v-if="model && !conditionalDisplay.isMobileApp" v-model="model" :flat="false" :with-validator="false" />
		<DownloadButton v-if="model && !conditionalDisplay.isMobileApp" v-model="model" :flat="false" :version="version" />
	</v-layout>
</template>

<script>
import DocumentVersionsModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard'
export default {
	name: 'DocumentActions',
	components: {
		DocumentZoomButtons: () => ({
			component: import('@/components/Documents/Preview/PreviewToolBar/Actions/DocumentZoomButtons')
		}),
		DownloadButton: () => ({
			component: import('@/components/Documents/Actions/Download/DownloadButton')
		}),
		RotateDocumentButtons: () => ({
			component: import('@/components/Documents/Actions/Rotate/RotateDocumentButtons')
		}),
		ValidationButtons: () => ({
			component: import('@/components/Documents/Actions/Validation/ValidationButtons')
		}),
		PrintButton: () => ({
			component: import('@/components/Documents/Actions/Print/PrintButton')
		}),
		UniqueNumberButton: () => ({
			component: import('@/components/Documents/Actions/UniqueNumber/UniqueNumberButton')
		})
	},
	mixins: [DocumentVersionsModuleGuard],
	inject: [
		'conditionalDisplay',
	],
	props: {
		value: {
			type: Object,
			required: false,
			default: null
		},
		isReadonly: {
			type: Boolean,
			required: false,
			default: false
		},
		isEditable: {
			type: Boolean,
			required: false,
			default: false
		},
		version: {
			type: Object,
			required: false,
			default: null
		},
		hasMultiplePages: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		}
	},
	methods: {
		getKeyboardEventsActionsMapping: function () {
			return [
				{
					actions: [
						{
							key: this.keyboard.enter,
							action: this.toggleValidation
						}
					]
				}
			]
		},
		toggleValidation: function () {
			this.eventBus.emit(this.events.TOGGLE_VALIDATION)
		}
	}
}
</script>
